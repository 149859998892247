.container {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: stretch;
}

.container iframe {
    width: 100%;
    border: 0;
}
